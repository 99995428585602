var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.message == 'saved' ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(_vm._s(_vm.$t("trust.movprop-success")))])]) : _vm._e(), _c('div', {
    staticClass: "col-md-12"
  }, [_c('router-link', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "to": {
        name: 'trust-onecent.part-f.immoveable',
        query: {
          trust_id: this.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/left-arrow.png",
      "height": "20",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "m-0",
    staticStyle: {
      "padding-left": "10px",
      "padding-top": "2px",
      "display": "inline-block"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back-to")) + " " + _vm._s(_vm.$t("account-list")) + " ")])])], 1), _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2 mt-3"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-bodyz"
  }, [_c('h5', {
    staticClass: "text-center mb-0 mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("acc-information")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Senarai penerima yang akan diagihkan dalam aset",
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('ul', {
    staticClass: "nav nav-tabs mt-4"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.routeName == 'trust-onecent.immoveable-property.details' ? 'active' : '',
    attrs: {
      "to": {
        name: 'trust-onecent.immoveable-property.details',
        query: {
          trust_id: _vm.trustId,
          property_id: _vm.properyId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("acc-information")) + " ")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.routeName == 'trust-onecent.immoveable-property.receiver' ? 'active' : '',
    attrs: {
      "to": {
        name: 'trust-onecent.immoveable-property.receiver',
        query: {
          trust_id: _vm.trustId,
          property_id: _vm.properyId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("acc-receiver")) + " ")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.routeName == 'trust-onecent.immoveable-property.attachment' ? 'active' : '',
    attrs: {
      "to": {
        name: 'trust-onecent.immoveable-property.attachment',
        query: {
          trust_id: _vm.trustId,
          property_id: _vm.properyId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("document")) + "/" + _vm._s(_vm.$t("appendix")) + " ")])], 1)]), _c('router-view')], 1)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }